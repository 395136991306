import { useState } from "react";

import * as Icons from "../../../shared/components/icons";
import AddStudent from "../forms/AddStudent";
import AddStudentOptionsModal from "../modals/AddStudentOptionsModal";
import UndismissibleModal from "../modals/UndismissibleModal";

import { useAuth } from "../../../store/authContext";
import { accountType } from "../../../constants/accountType";
import Modal from "./Modal";

export const AddStudentButton = ({ className, onIncrementStudent }) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [showStudentModal, setShowStudentModal] = useState(false);

  const [showStudentOptionModal, setShowStudentOptionModal] = useState(false);

  const shouldShowStudentOptionModal = () => {
    setShowStudentOptionModal(prev => !prev);
  };
  const shouldShowStudentModal = () => {
    setShowStudentModal(prev => !prev);
  };

  const addStudentButtonContent = () => {
    return (
      <>
        {/* <UndismissibleModal
          width='w-[450px]'
          maxHeight='max-h-[600px]'
          showModal={showStudentModal}
          showCloseButton={false}
        >
          <AddStudent
            closeModal={shouldShowStudentModal}
            onIncrementStudent={onIncrementStudent}
          />
        </UndismissibleModal> */}
        <Modal showModal={showStudentModal} onClose={shouldShowStudentModal}>
          <AddStudent onIncrementStudent={onIncrementStudent} />
        </Modal>

        <AddStudentOptionsModal
          showCloseOptionModal={false}
          closeOptionModal={() => setShowStudentOptionModal(false)}
          showModal={showStudentOptionModal}
          setShowModal={setShowStudentOptionModal}
          setShowAddStudentModal={() => {
            document.body.style.overflow = "hidden";
            setShowStudentOptionModal(false);
            shouldShowStudentModal();
          }}
        />

        <button
          className={[
            "inline-flex h-[40px] w-[156px] transform items-center justify-center gap-[6px] rounded-[5px] bg-[#437EF7] py-[8px] px-[12px] text-[14px] font-semibold text-white shadow-sm",
            className,
          ].join(" ")}
          onClick={shouldShowStudentOptionModal}
        >
          Add Student
        </button>
      </>
    );
  };

  return (
    <>{userType === accountType.SCHOOL ? addStudentButtonContent() : null}</>
  );
};
